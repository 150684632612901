import React from "react";
import Axios from "axios";
import countries from "../../Countries.json";
import worldLocation from "../../avis_locations2.json";
import { API_URL } from "../../core/constant.js";
import DatePicker from "react-datepicker";
import { times } from "../../core/time.js";
export default class OWCR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      countryPick: "",
      date: new Date(),
      time: "12:00 AM",
      return_location: "",
      return_date: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      return_time: "12:00 AM",
      age: "26",
      country: "ID",
      user_id: "",
      offer_code: "",
      vehicle: "",
      locationName: "",
      searchData: [], // Add your data here
      searchResults: [],
      returnlocationName: "",
      returnsearchData: [], // Add your data here
      returnsearchResults: [],
      isShowOfferCode: false,
      isShowWizardCode: false,
      awd_number: "",
      coupon_code: "",
      wizard_number: "",
      last_name: "",
      excludedFields: ["NUM", "Sitecode"],
      selectedLocation: null,
    };
  }

  submit(event) {
    event.preventDefault();

    Axios.post(
      `${API_URL}/api/booking/check`,
      // `http://localhost/lare/public/api/booking/check`,
      {
        location: this.state.location,
        date: this.state.date,
        time: this.state.time,
        return_location: this.state.return_location,
        return_date: this.state.return_date,
        return_time: this.state.return_time,
        age: this.state.age,
        country: this.state.country,
        user_id: this.state.user_id,
        offer_code: this.state.offer_code,
        vehicle: this.state.vehicle,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        Axios.get(`https://stage.abgapiservices.com/oauth/token/v1`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            client_id: "04e19f27",
            client_secret: "4ed319e489d262258325686bf0902603",
          },
        }).then((res) => {
          //  console.log(res.data.access_token);
          localStorage.setItem("bebe", res.data.access_token);
        });

        this.props.navigate("/choosecars", {
          state: {
            location: this.state.location,
            date: this.state.date,
            time: this.state.time,
            return_location:
              this.state.return_location == ""
                ? this.state.location
                : this.state.return_location,
            return_date: this.state.return_date,
            return_time: this.state.return_time,
            age: this.state.age,
            country: this.state.country,
            user_id: this.state.user_id,
            offer_code: this.state.offer_code,
            vehicle: this.state.vehicle,
            awd_number: this.state.awd_number,
            coupon_code: this.state.coupon_code,
            wizard_number: this.state.wizard_number,
            last_name: this.state.last_name,
            countryPick: this.state.countryPick,
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("Please Sign In First");
        } else {
          alert(error.response.data.msg);
        }
      });
  }

  groupResultsByGroup() {
    const groupedResults = {};
    this.state.searchResults.forEach((result) => {
      if (!groupedResults[result.group]) {
        groupedResults[result.group] = [];
      }
      groupedResults[result.group].push(result);
    });
    return Object.values(groupedResults);
  }

  groupResultsByGroupReturn() {
    const groupedResults = {};
    this.state.returnsearchResults.forEach((result) => {
      if (!groupedResults[result.group]) {
        groupedResults[result.group] = [];
      }
      groupedResults[result.group].push(result);
    });
    return Object.values(groupedResults);
  }

  handleBackspaceOrDelete = (searchTerm) => {
    // Handle backspace or delete key
    if (searchTerm.trim() === "") {
      // Clear the search results and selected location when the input is empty
      this.setState({ searchResults: [], selectedLocation: null });
    }
  };

  location_change = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    this.setState({
      location: searchTerm,
    });
    console.log(event.key);
    if (event.key === "Backspace" || event.key === "Delete") {
      // Handle backspace or delete separately
      this.handleBackspaceOrDelete(searchTerm);
      return;
    }

    this.setState({ location: searchTerm });

    if (searchTerm.trim() === "") {
      // Clear the search results and selected location when the input is empty
      this.setState({ searchResults: [], selectedLocation: null });
      return;
    }

    const filteredResults = worldLocation.filter((item) => {
      // Check if the key is not in the excluded fields and a match is found in any field
      for (const key in item) {
        if (
          !this.state.excludedFields.includes(key) &&
          item[key] &&
          item[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }

      // Additional grouping logic
      if (
        (item.Descriptor &&
          item.Descriptor.toLowerCase().includes("airport")) ||
        (item.Address1 && item.Address1.toLowerCase().includes("airport")) ||
        (item.Address2 && item.Address2.toLowerCase().includes("airport"))
      ) {
        item.group = "Airport";
      } else if (
        item["Postal Code"] &&
        item["Postal Code"].toString().trim() !== ""
      ) {
        item.group = "Neighborhood";
      } else {
        item.group = "City";
      }

      return false;
    });

    this.setState({ searchResults: filteredResults });
  };

  handleLocationClick = (selectedLocation) => {
    console.log("Selected Location:", selectedLocation);
    document.getElementById("locationValue").value =
      selectedLocation.Descriptor +
      " " +
      selectedLocation.Address1 +
      ", " +
      selectedLocation.city +
      ", " +
      selectedLocation.MNE;

    const descriptor = selectedLocation.Descriptor || "";
    const address1 = selectedLocation.Address1 || "";
    const city = selectedLocation.city || "";
    const mne = selectedLocation.MNE || "";

    const combinedLocation = `${descriptor} ${address1}, ${city}, ${mne}`;
    console.log("Combined Location:", combinedLocation);
    this.setState({
      location: selectedLocation.MNE,
      locationName: combinedLocation,
      countryPick: selectedLocation.Country,
      searchResults: [],
      selectedLocation: combinedLocation,
    });
  };

  handleLocationClickReturn = (selectedLocation) => {
    console.log("Selected Location:", selectedLocation);
    document.getElementById("locationReturnValue").value =
      selectedLocation.Descriptor +
      " " +
      selectedLocation.Address1 +
      ", " +
      selectedLocation.city +
      ", " +
      selectedLocation.MNE;

    this.setState({
      return_location: selectedLocation.MNE,
      returnlocationName: selectedLocation.Descriptor,
      countryPick: selectedLocation.Country,
      returnsearchResults: [],
      selectedLocation: selectedLocation.Descriptor,
    });
  };

  date_change(event) {
    const selectedDate = new Date(event);
    const returnDate = new Date(
      selectedDate.getTime() + 2 * 24 * 60 * 60 * 1000
    );
    this.setState({
      date: event,
      return_date: returnDate,
    });
  }

  time_change(event) {
    this.setState({
      time: event.target.value,
      return_time: event.target.value,
    });
  }

  return_location_change(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.setState({
      return_location: searchTerm,
    });
    console.log(event.key);
    if (event.key === "Backspace" || event.key === "Delete") {
      // Handle backspace or delete separately
      this.handleBackspaceOrDelete(searchTerm);
      return;
    }

    this.setState({ return_location: searchTerm });

    if (searchTerm.trim() === "") {
      // Clear the search results and selected location when the input is empty
      this.setState({ returnsearchResults: [], selectedLocation: null });
      return;
    }

    const filteredResults = worldLocation.filter((item) => {
      // Check if the key is not in the excluded fields and a match is found in any field
      for (const key in item) {
        if (
          !this.state.excludedFields.includes(key) &&
          item[key] &&
          item[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }

      // Additional grouping logic
      if (
        (item.Descriptor &&
          item.Descriptor.toLowerCase().includes("airport")) ||
        (item.Address1 && item.Address1.toLowerCase().includes("airport")) ||
        (item.Address2 && item.Address2.toLowerCase().includes("airport"))
      ) {
        item.group = "Airport";
      } else if (
        item["Postal Code"] &&
        item["Postal Code"].toString().trim() !== ""
      ) {
        item.group = "Neighborhood";
      } else {
        item.group = "City";
      }

      return false;
    });

    this.setState({ returnsearchResults: filteredResults });
  }

  return_date_change(event) {
    this.setState({
      return_date: event,
    });
  }

  return_time_change(event) {
    this.setState({
      return_time: event.target.value,
    });
  }

  age_change(event) {
    this.setState({
      age: event.target.value,
    });
  }

  country_change(event) {
    this.setState({
      country: event.target.value,
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const time = times();
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/onewaycarrental1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "700px",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">Products & Services</li>
                    <li className="breadcrumb-item">Services</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      One-Way Car Rental
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-one" style={myStyle}>
          <div className="banner-one__inner py-4">
            <div className="container">
              <div className="banner-one__content text-center">
                <div className="title">
                  <h3 className="text-white text-up">
                    {" "}
                    One-Way Car Rental From Avis
                  </h3>
                </div>

                <form
                  action="#"
                  className="comment-one__form contact-form-validated"
                  novalidate="novalidate"
                  onSubmit={(e) => this.submit(e)}
                >
                  <div className="banner-one__tab-box">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="banner-one__tab tabs-box">
                          <div className="tabs-content">
                            <div className="tab active-tab" id="places">
                              <div className="banner-one__tab-content-item">
                                <div className="banner-one__tab-content-places">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div
                                        className="banner-one__tab-content-places-form"
                                        style={{ padding: "30px" }}
                                      >
                                        <div className="row">
                                          <div className="col-sm-6 p-0 border-end">
                                            <div>
                                              <input
                                                required
                                                type="text"
                                                className="form-control input-avis py-3"
                                                placeholder="Enter your pick-up location or zip code"
                                                id="locationValue"
                                                style={{ borderRadius: "0px" }}
                                                onChange={(e) =>
                                                  this.location_change(e)
                                                }
                                              />

                                              {this.state.searchResults.length >
                                              0 ? (
                                                <div
                                                  className="live-search bg-light p-2"
                                                  style={{
                                                    position: "absolute",
                                                    zIndex: 1,
                                                    width: "100%",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                  }}
                                                >
                                                  {this.groupResultsByGroup().map(
                                                    (
                                                      groupedResults,
                                                      groupIndex
                                                    ) => (
                                                      <div key={groupIndex}>
                                                        <div
                                                          className="d-flex"
                                                          style={{
                                                            borderBottom:
                                                              "1px solid #ddd",
                                                          }}
                                                        >
                                                          <h5>
                                                            {
                                                              groupedResults[0]
                                                                .group
                                                            }
                                                          </h5>
                                                          <span className="ms-auto">
                                                            {
                                                              groupedResults.length
                                                            }{" "}
                                                            results
                                                          </span>
                                                        </div>
                                                        {groupedResults.map(
                                                          (result, index) => (
                                                            <div
                                                              key={index}
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                padding: "5px",
                                                              }}
                                                            >
                                                              <p
                                                                id="descriptionLoc"
                                                                onClick={() =>
                                                                  this.handleLocationClick(
                                                                    result
                                                                  )
                                                                }
                                                              >
                                                                <strong className="text-primary">
                                                                  {
                                                                    result.Descriptor
                                                                  }
                                                                </strong>
                                                                ,{" "}
                                                                {
                                                                  result.Address1
                                                                }
                                                                , {result?.city}
                                                                ,{" "}
                                                                {result[
                                                                  "Postal Code"
                                                                ] ??
                                                                  "undefined"}{" "}
                                                                <strong className="text-primary">
                                                                  {result.MNE}
                                                                </strong>
                                                              </p>

                                                              {/* Include other fields you want to display */}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>

                                          <div className="col-sm-3 p-0 border-end">
                                            <DatePicker
                                              wrapperClassName="datepicker"
                                              selected={
                                                this.state.date || new Date()
                                              }
                                              onChange={(e) =>
                                                this.date_change(e)
                                              }
                                              dateFormat={"dd-MM-yyyy"}
                                              className="form-control input-avis py-3"
                                              value={this.state.date}
                                            />
                                          </div>
                                          <div className="col-sm-3 p-0">
                                            <select
                                              name=""
                                              id=""
                                              required
                                              className="form-control input-avis py-3"
                                              style={{ borderRadius: "0px" }}
                                              onChange={(e) =>
                                                this.time_change(e)
                                              }
                                              value={this.state.time}
                                            >
                                              {time.map((data, i) => (
                                                <option value={data.value}>
                                                  {data.field}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>

                                        <div className="row mt-2">
                                          <div className="col-sm-6 p-0 border-end">
                                            <div>
                                              <input
                                                type="text"
                                                id="locationReturnValue"
                                                className="form-control input-avis py-3"
                                                placeholder="Return on same location"
                                                style={{ borderRadius: "0px" }}
                                                onChange={(e) =>
                                                  this.return_location_change(e)
                                                }
                                              />

                                              {this.state.returnsearchResults
                                                .length > 0 ? (
                                                <div
                                                  className="live-search bg-light p-2"
                                                  style={{
                                                    position: "absolute",
                                                    zIndex: 1,
                                                    width: "100%",
                                                  }}
                                                >
                                                  {this.groupResultsByGroupReturn().map(
                                                    (
                                                      groupedResults,
                                                      groupIndex
                                                    ) => (
                                                      <div key={groupIndex}>
                                                        <div
                                                          className="d-flex"
                                                          style={{
                                                            borderBottom:
                                                              "1px solid #ddd",
                                                          }}
                                                        >
                                                          <h5>
                                                            {
                                                              groupedResults[0]
                                                                .group
                                                            }
                                                          </h5>
                                                          <span className="ms-auto">
                                                            {
                                                              groupedResults.length
                                                            }{" "}
                                                            results
                                                          </span>
                                                        </div>
                                                        {groupedResults.map(
                                                          (result, index) => (
                                                            <div
                                                              key={index}
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                padding: "5px",
                                                              }}
                                                            >
                                                              <p
                                                                id="descriptionLoc"
                                                                onClick={() =>
                                                                  this.handleLocationClickReturn(
                                                                    result
                                                                  )
                                                                }
                                                              >
                                                                <strong className="text-primary">
                                                                  {
                                                                    result.Descriptor
                                                                  }
                                                                </strong>
                                                                ,{" "}
                                                                {
                                                                  result.Address1
                                                                }
                                                                , {result?.city}
                                                                ,{" "}
                                                                {result[
                                                                  "Postal Code"
                                                                ] ??
                                                                  "undefined"}{" "}
                                                                <strong className="text-primary">
                                                                  {result.MNE}
                                                                </strong>
                                                              </p>

                                                              {/* Include other fields you want to display */}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="col-sm-3 p-0 border-end">
                                            <DatePicker
                                              wrapperClassName="datepicker"
                                              selected={this.state.return_date}
                                              onChange={(e) =>
                                                this.return_date_change(e)
                                              }
                                              dateFormat={"dd-MM-yyyy"}
                                              value={this.state.return_date}
                                              className="form-control input-avis py-3"
                                            />
                                          </div>
                                          <div className="col-sm-3 p-0">
                                            <select
                                              name=""
                                              id=""
                                              required
                                              className="form-control input-avis py-3"
                                              style={{ borderRadius: "0px" }}
                                              onChange={(e) =>
                                                this.return_time_change(e)
                                              }
                                              value={this.state.return_time}
                                            >
                                              {time.map((data, i) => (
                                                <option value={data.value}>
                                                  {data.field}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>

                                        <div className="row mt-2">
                                          <div className="col-lg-2 col-sm-4 p-0">
                                            <div className="input-group">
                                              <span className="input-group-text">
                                                Age
                                              </span>
                                              <select
                                                required
                                                className="form-control"
                                                style={{ borderRadius: "0px" }}
                                                onChange={(e) =>
                                                  this.setState({
                                                    age: e.target.value,
                                                  })
                                                }
                                              >
                                                <option value={"26"} selected>
                                                  25+
                                                </option>
                                                <option value={"24"}>24</option>
                                                <option value={"23"}>23</option>
                                                <option value={"22"}>22</option>
                                                <option value={"21"}>21</option>
                                                <option value={"20"}>20</option>
                                                <option value={"19"}>19</option>
                                                <option value={"18"}>18</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-sm-4 p-0">
                                            <div className="input-group">
                                              <span className="input-group-text">
                                                Country
                                              </span>
                                              <select
                                                required
                                                className="form-control"
                                                style={{ borderRadius: "0px" }}
                                                onChange={(e) =>
                                                  this.setState({
                                                    country: e.target.value,
                                                  })
                                                }
                                              >
                                                {countries.map((c, index) => (
                                                  <option
                                                    key={index}
                                                    value={c.code}
                                                    selected={
                                                      c.name == "Indonesia"
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {c.name}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>
                                          {/* <div className='col-lg-2 col-sm-4 p-0'>
                                        <button className='btn btn-light w-100' style={{ borderRadius: "0px" }}>Add Customer ID *</button>
                                    </div> */}
                                          <div className="col-lg-2 col-sm-6 p-0 ">
                                            <button
                                              type="button"
                                              className="btn btn-light w-100"
                                              style={{ borderRadius: "0px" }}
                                              onClick={() =>
                                                this.setState({
                                                  isShowWizardCode:
                                                    !this.state
                                                      .isShowWizardCode,
                                                  isShowOfferCode: false,
                                                })
                                              }
                                            >
                                              Avis Wizard Number
                                            </button>
                                          </div>
                                          <div className="col-lg-2 col-sm-6 p-0">
                                            <button
                                              type="button"
                                              className="btn btn-light w-100"
                                              style={{ borderRadius: "0px" }}
                                              onClick={() =>
                                                this.setState({
                                                  isShowOfferCode:
                                                    !this.state.isShowOfferCode,
                                                  isShowWizardCode: false,
                                                })
                                              }
                                            >
                                              Offer Codes{" "}
                                            </button>
                                          </div>
                                          <div className="col-lg-2 col-sm-6 p-0">
                                            <input
                                              className="form-control"
                                              placeholder="Vehicle Type *"
                                              style={{ borderRadius: "0px" }}
                                            />
                                          </div>
                                          <div className="col-sm-12 pb-3">
                                            {/* <div className='row p-5 mx-1' style={{backgroundColor:"#fff"}}>
                                            <div className='col-sm-6'>
                                                <label>Enter your Fastbreak, rapidRez or online ID</label>
                                            </div>
                                            <div className='col-sm-3'>
                                                <input required type="text" placeholder='Fistt Name'/>
                                            </div>
                                            <div className='col-sm-3'>
                                                <input required type="text" placeholder='Last Name'/>
                                            </div>
                                        </div> */}
                                          </div>
                                        </div>

                                        {this.state.isShowOfferCode ? (
                                          <div className="container bg-light py-3">
                                            <div className="row justify-content-start align-items-center">
                                              <div className="col-sm-3">
                                                <p className="text-center">
                                                  Enter a Discount Code
                                                </p>
                                              </div>
                                              <div className="col-sm-3">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  name="awd_number"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      awd_number:
                                                        e.target.value,
                                                    })
                                                  }
                                                  placeholder="AWD #"
                                                />
                                              </div>
                                              <div className="col-sm-3">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  name="coupon_code"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      coupon_code:
                                                        e.target.value,
                                                    })
                                                  }
                                                  placeholder="Coupon Code"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        {this.state.isShowWizardCode ? (
                                          <div className="container bg-light py-3">
                                            <div className="row justify-content-start align-items-center">
                                              <div className="col-sm-4">
                                                <p className="text-center">
                                                  Enter Wizard Number and Last
                                                  Name
                                                </p>
                                              </div>
                                              <div className="col-sm-3">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  name="wizard_number"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      wizard_number:
                                                        e.target.value,
                                                    })
                                                  }
                                                  placeholder="Wizard Number"
                                                />
                                              </div>
                                              <div className="col-sm-3">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  name="last_name"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      last_name: e.target.value,
                                                    })
                                                  }
                                                  placeholder="Last Name"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="banner-one__categories">
                    <div className="title">
                      <button className="btn btn-avis-black" type="submit">
                        Select My Car
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-2">
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  Want to go from A to B without having to double back to where
                  you started, simply to return your hire car?
                </h3>
                <p className="text-avis mt-2">
                  Hire a car in and drop it off in a different location that
                  suits your schedule.
                </p>
                <p className="text-avis mt-2">
                  One-way car rental with Avis makes it easy to go from A to B
                  without driving full circle.
                </p>
                <p className="text-avis mt-2">
                  Simply, pick up your vehicle from one of our offices and drop
                  it off at another location at the end of your trip.
                </p>
                <p className="text-avis mt-2">
                  It’s quick, easy and efficient, and it means you can plan your
                  journey exactly as you please.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  Why choose Avis for one-way car hire?
                </h3>
                <p className="text-avis mt-2">
                  The ticket to ultimate travel freedom, our one-way car hire is
                  refreshingly easy to book.
                </p>
                <p className="text-avis mt-2">
                  With a wide range of routes available across the continent,
                  you can:
                </p>
                <p className="text-avis mt-2">
                  • Collect your rental vehicle from one of our many offices
                  across Europe or other countries, and return it to us at your
                  final destination.
                </p>
                <p className="text-avis mt-2">
                  • Drop off your hire car at another office in the same city,
                  somewhere further afield in the same country, or even at one
                  of our offices in a different country.
                </p>
                <p className="text-avis mt-2">
                  Just specify your destination when you make your reservation,
                  one way rental will be included if the location offers such
                  service.
                </p>
                <p className="text-avis mt-2">
                  We have car hire locations at nearly every major airport on
                  the continent, as well as at most central train stations,
                  ferry ports and in convenient downtown locations too. Many of
                  our offices offer a 24-hour drop-off facility, which makes a
                  one-way car hire hassle-free and very user-friendly.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

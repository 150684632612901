import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, BRAND } from "../../core/constant";
import partnerData from "../../avis_partnerships.json";
import { getToken } from "../../core/functions";
import { format } from "date-fns";
import axios from "axios";

const RB = (props) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailPromoCheck, setEmailPromoCheck] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryPick, setCountryPick] = useState("Indonesia");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [flightInfo, setFlightInfo] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [termChecked, setTermChecked] = useState(false);
  const [partnerCode, setPartnerCode] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");

  const [bookingNumber, setBookingNumber] = useState("");
  const [requestTime, setRequestTime] = useState("");

  const generateRandomNumbers = () => {
    const randomNumbers = [];
    for (let i = 0; i < 9; i++) {
      randomNumbers.push(Math.floor(Math.random() * 100)); // Adjust the range as needed
    }
    return randomNumbers;
  };

  const {
    pickupNameLocation,
    dropoffNameLocation,
    pickupCityLocation,
    dropoffCityLocation,
    pickupCodeLocation,
    dropoffCodeLocation,
    pickupAddress1Location,
    dropoffAddress1Location,
    pickupAddress2Location,
    dropoffAddress2Location,
    pickupPostalCodeLocation,
    dropoffPostalCodeLocation,
    pickupTelephoneLocation,
    dropoffTelephoneLocation,
    pickupDate,
    dropoffDate,
    pickupCoordinate,
    dropoffCoordinate,
    currencyFrom,
    insurance,
    couponCode,
    paymentType,
    price,
    tax,
    total,
    fees,
    vehicle,
    rateCode,
    age,
    totalIdr,
    baseIdr,
    taxIdr,
    image,
    make,
    model,
    name,
  } = props.before;

  console.log(props.before, "props");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataSubmit = {
      product: {
        brand: BRAND,
        iata_number: paymentType === "paylaters" ? "0113094R" : "0113105R",
      },
      transaction: {
        transaction_id: generateRandomNumbers().toString(),
      },
      reservation: {
        email_notification: emailPromoCheck,
        pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
        pickup_location: pickupCodeLocation,
        dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
        dropoff_location: dropoffCodeLocation,
        vehicle_sipp_code: vehicle.category.sipp_code,
      },
      rate_totals: {
        rate: {
          rate_code: rateCode,
          country_code: "ID",
          membership: {
            code: memberNumber,
            partner_membership_code: partnerCode,
          },
        },
      },
      customer: {
        contact: {
          first_name: firstName,
          last_name: lastName,
          telephone: phone,
          email: email,
          age: Number(age),
        },
        address: {
          country_code: "ID",
          address_line_1: address1,
          address_line_2: address2,
          address_line_3: "",
          city: city,
          postal_code: zipCode,
        },
      },
      payment_preference: {
        type: "CentralBillingAccount",
        id: generateRandomNumbers().toString(),
        fixed_value: "FixedValue",
        electronic_indicator: true,
        amount: vehicle.rate_totals.pay_later.reservation_total,
        currency: "USD",
      },
    };

    try {
      const brandRes = await axios.post(
        "https://stage.abgapiservices.com/cars/reservation/v2",
        dataSubmit,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("bebe"),
            client_id: "04e19f27",
          },
        }
      );

      if (brandRes.status === 201) {
        setBookingNumber(brandRes.data.reservation.confirmation.number);
        setRequestTime(brandRes.data.status.request_time);

        const localRes = await axios.post(
          `${API_URL}/api/booking/save`,
          {
            productType: BRAND,
            location: pickupCodeLocation,
            paymentType: paymentType,
            bookingNumber: brandRes.data.reservation.confirmation.number,
            cardType: cardType,
            cardNumber: cardNumber,
            expirationMonth: expirationMonth,
            expirationYear: expirationYear,
            date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
            isPromo: emailPromoCheck,
            time: format(pickupDate, "HH:mm a"),
            return_location: dropoffCodeLocation,
            return_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
            return_time: format(dropoffDate, "HH:mm a"),
            age: age,
            emailForm: email,
            firstName: firstName,
            lastName: lastName,
            country: "ID",
            customer_id: localStorage.getItem("customer_id"),
            countryCodePick: countryPick,
            requestTime: brandRes.data.status.request_time,
            offer_code: couponCode,
            car_id: vehicle,
            total: totalIdr,
            tax: taxIdr,
            base_rate: baseIdr,
            car_img: image,
            car_name: name,
            car_model: model,
            car_make: make,
            phone: phone,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zipCode: zipCode,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (localRes.status === 200) {
          console.log("success");
          setTermChecked(false);
          navigate("/bookingdetail", {
            state: {
              booking: localRes.data.booking_code,
              price: price,
              tax: tax,
              total: total,
              priceIdr: baseIdr,
              taxIdr: taxIdr,
              totalIdr: totalIdr,
              extraData: insurance,
              paymentType: paymentType,
              pickupDate: pickupDate,
              dropoffDate: dropoffDate,
              pickupCodeLocation: pickupCodeLocation,
              dropoffCodeLocation: dropoffCodeLocation,
              pickupNameLocation: pickupNameLocation,
              dropoffNameLocation: dropoffNameLocation,
              pickupTelephoneLocation: pickupTelephoneLocation,
              dropoffTelephoneLocation: dropoffTelephoneLocation,
              pickupAddress1Location: pickupAddress1Location,
              dropoffAddress1Location: dropoffAddress1Location,
              pickupAddress2Location: pickupAddress2Location,
              dropoffAddress2Location: dropoffAddress2Location,
              pickupCityLocation: pickupCityLocation,
              dropoffCityLocation: dropoffCityLocation,
              pickupPostalCodeLocation: pickupPostalCodeLocation,
              dropoffPostalCodeLocation: dropoffPostalCodeLocation,
              pickupCoordinate: pickupCoordinate,
              dropoffCoordinate: dropoffCoordinate,
              vehicle: vehicle,
              bookingNumber: brandRes.data.reservation.confirmation.number,
              requestTime: brandRes.data.status.request_time,
              lastName: lastName,
              firstName: firstName,
              telephone: phone,
              email: email,
              age: age,
              address_line_1: address1,
              address_line_2: address2,
              city: city,
              postal_code: zipCode,
            },
          });
        } else {
          alert(localRes.data.msg);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const token = async () => {
    await getToken();
  };

  useEffect(() => {
    token().then((res) => console.log(res));
  }, []);

  const myLink = {
    color: "rgb(212, 0, 42)",
  };
  const myBorder = {
    borderBottom: "2px solid rgb(212, 0, 42)",
  };
  const myLinkDis = {
    color: "rgb(168, 168, 168)",
  };
  const myBorderDis = {
    borderBottom: "2px solid rgb(168, 168, 168)",
  };
  const btnLink = {
    backgroundColor: "rgb(212, 0, 42)",
    color: "rgb(255, 255, 255)",
    padding: "0px 50px",
  };

  return (
    <div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <p style={myLinkDis}>1</p>
              <p style={myBorderDis}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLinkDis}>2</p>
              <p style={myBorderDis}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLinkDis}>3</p>
              <p style={myBorderDis}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLink}>4 YOUR INFORMATION</p>
              <p style={myBorder}></p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6 border-bottom">
                  <div className="row text-black">
                    <div className="col-sm-6">
                      <p style={myLink}>Pick-Up</p>
                      <p>
                        <b>{pickupNameLocation}</b>
                        <br />
                        {pickupCityLocation},{pickupCodeLocation},
                        <br />
                        {pickupAddress1Location},{pickupAddress2Location},
                        {pickupPostalCodeLocation}
                        <br />
                        <b>{pickupTelephoneLocation}</b>
                        <br />
                        <b>Location Code: </b> {pickupCodeLocation}
                      </p>
                      <p>
                        <b>{format(pickupDate, "HH:mm a MMMM d, yyyy")}</b>
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p style={myLink}>Return</p>
                      <p>
                        <b>{dropoffNameLocation}</b>
                        <br />
                        {dropoffCityLocation},{dropoffCodeLocation}
                        <br />
                        {dropoffAddress1Location},{dropoffAddress2Location},
                        {dropoffPostalCodeLocation}
                        <br />
                        <b>{dropoffTelephoneLocation}</b>
                        <br />
                        <b>Location Code: </b> {dropoffCodeLocation}
                      </p>
                      <p>
                        <b>{format(dropoffDate, "HH:mm a MMMM d, yyyy")}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 border-start border-bottom">
                  <div className="row text-black">
                    <div className="col-sm-6">
                      <p>Base Rate</p>
                      <p style={myLink}>Taxes & Fees</p>
                      <p>Estimated Total</p>
                      <p style={myLink}>See Rate Terms</p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <p>
                        {currencyFrom} {price.toFixed(2)}
                      </p>
                      <p>
                        {currencyFrom} {tax.toFixed(2)}
                      </p>
                      <p>
                        <b>
                          {currencyFrom} {total.toFixed(2)}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="row mt-2 text-black">
                    <div className="col-sm-3">
                      <img
                        alt="carImage"
                        src={props?.before?.image}
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-sm-9">
                      {/* <p><h5 className='text-dark'>{this.props.car.name}</h5></p> */}
                      <p>
                        <h5 className="text-dark">{props?.before?.name}</h5>
                      </p>
                      <p>
                        {props?.before?.make} {props?.before?.model}
                      </p>
                      <div className="col-sm-9">
                        <p>
                          <img src="/assets/images/icon/user.png" alt="user" />{" "}
                          {props?.before?.vehicle?.capacity.seats}
                          {props.before.vehicle.capacity &&
                            props.before.vehicle.capacity.luggage_capacity &&
                            props.before.vehicle.capacity.luggage_capacity
                              .large_suitcase && (
                              <>
                                <img
                                  alt="suitcase"
                                  src="/assets/images/icon/suitcase.png"
                                  className="ms-2"
                                />{" "}
                                {props?.before?.vehicle?.capacity
                                  ?.luggage_capacity.large_suitcase ?? 0}
                                L
                                <img
                                  alt="suitcase"
                                  src="/assets/images/icon/suitcase.png"
                                  className="ms-2"
                                />{" "}
                                {props?.before?.vehicle?.capacity
                                  ?.luggage_capacity.large_suitcase ?? 0}
                                S
                              </>
                            )}
                        </p>
                        <p>
                          {
                            props?.before?.vehicle?.category
                              ?.vehicle_transmission
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 border-start text-success">
                  <p className="text-end">Inclusions: </p>
                  <ul className="text-end">
                    {insurance?.map((item, index) => (
                      <li>{item.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="container">
            <div className="row mt-4 justify-content-md-center">
              <div className="col-sm-8">
                <p className="pb-3">
                  <b>IMPORTANT</b>: Cancelling up to 48 hours prior to scheduled
                  pickup time, You will receive a refund of your prepaid amount,
                  minus a <b>{fees}</b> cancellation fee.
                </p>
                <div className="row">
                  <div className="col-sm-12">
                    <h5 className="text-dark">YOUR INFORMATION</h5>
                  </div>
                  <div className="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>First Name</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      required
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Last Name</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      required
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Email</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      required
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-11 d-flex justify-content-center">
                    <input
                      type="checkbox"
                      className="me-1"
                      id="isPromo"
                      onChange={() => setEmailPromoCheck(!emailPromoCheck)}
                    />
                    <label for={"isPromo"}>
                      send me email promotions and offers
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Phone</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      type="number"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-sm-12">
                    <p>
                      At time of Check-out you may be asked to provide a second
                      form of goverment identification.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Country</label>
                  </div>
                  <div className="col-sm-8">
                    <select className="form-control">
                      <option>Indonesia</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Address Line 1</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>
                      Address Line 2 <small>(optional)</small>
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>City</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>State/Province</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Zip/Pastel Code</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <h5 className="text-dark">TRAVEL INFORMATION</h5>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <p>
                      We recommend providing your Travel information below.
                      These details help us stay informed any changes in your
                      travel plans and allow our agents to better to prepare for
                      your arrival.
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Flight Info</label>
                  </div>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      placeholder="Flight Number"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <h5 className="text-dark">
                      Frequent Travel Program for Miles/Points
                    </h5>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <p>
                      Rewards program points will be awarded on eligible
                      rentals.
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label>Rewards Program</label>
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="form-control"
                      onChange={(e) => setPartnerCode(e.target.value)}
                    >
                      <option value="">Program Partner Name</option>
                      {partnerData.map((data, index) => {
                        return (
                          <option key={index} value={data["Partner Code"]}>
                            {data.Partner}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      placeholder="Member Number"
                      onChange={(e) => setMemberNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <h5 className="text-dark">TERMS & CONDITIONS</h5>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-8">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                        onChange={() => setTermChecked(!termChecked)}
                      />
                      <label className="form-check-label" for="defaultCheck2">
                        <b>
                          I have read and accept the{" "}
                          <a
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                            href="https://www.avis.co.uk/TermsAndCondition"
                            rel="noreferrer"
                          >
                            <span>Location Policies</span> and{" "}
                            <span>Rental Terms and Conditions.</span>
                          </a>
                        </b>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4 text-end">
                    <button
                      className="btn py-2"
                      style={btnLink}
                      disabled={!termChecked}
                      type="submit"
                    >
                      Reserve
                    </button>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default RB;

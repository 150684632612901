import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatRupiah, getToken } from "../../core/functions";
import { API_URL } from "../../core/constant";
import HeaderAlt from "../../common/header/HeaderAlt";
import FooterOne from "../../common/footer/FooterOne";
import { useLocation } from "react-router-dom";
import { generateRandomNumbers } from "../../core/functions";
import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { format } from "date-fns";

const BD = (props) => {
  const [header, setHeader] = useState([]);
  const [detail, setDetail] = useState([]);
  const [midLink, setMidLink] = useState("");
  const [pickupLat, setPickupLat] = useState();
  const [pickupLng, setPickupLng] = useState();
  const [dropoffLat, setDropoffLat] = useState();
  const [dropoffLng, setDropoffLng] = useState();

  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [errorGetCurrentLocation, setErrorGetCurrentLocation] = useState("");

  const {
    booking,
    requestTime,
    transactionId,
    pickupNameLocation,
    dropoffNameLocation,
    pickupCityLocation,
    dropoffCityLocation,
    pickupCodeLocation,
    dropoffCodeLocation,
    pickupAddress1Location,
    dropoffAddress1Location,
    pickupAddress2Location,
    dropoffAddress2Location,
    pickupPostalCodeLocation,
    dropoffPostalCodeLocation,
    pickupTelephoneLocation,
    dropoffTelephoneLocation,
    pickupCoordinate,
    dropoffCoordinate,
    pickupDate,
    dropoffDate,
    vehicle,
    couponCode,
    make,
    model,
    name,
    image,
    telephone,
    email,
    age,
    address_line_1,
    address_line_2,
    city,
    postal_code,
    lastName,
    firstName,
    bookingNumber,
    paymentType,
    priceIdr,
    taxIdr,
    totalIdr,
    total,
    extraData,
  } = props.before;

  console.log(props.before);

  localStorage.setItem("bookingNumber", bookingNumber);
  localStorage.setItem("pickup_location", pickupCodeLocation);
  localStorage.setItem("dropoff_location", dropoffCodeLocation);
  localStorage.setItem("pickup_date", pickupDate);
  localStorage.setItem("dropoff_date", dropoffDate);
  localStorage.setItem("total_price", totalIdr);
  localStorage.setItem("location", pickupNameLocation);
  localStorage.setItem("pickupLat", pickupCoordinate[0]);
  localStorage.setItem("pickupLng", pickupCoordinate[1]);
  localStorage.setItem("dropoffLat", dropoffCoordinate[0]);
  localStorage.setItem("dropoffLng", dropoffCoordinate[1]);
  localStorage.setItem("requestTime", requestTime);
  localStorage.setItem("currentLatitude", currentLatitude);
  localStorage.setItem("currentLongitude", currentLongitude);
  localStorage.setItem("nameLocation", pickupNameLocation);
  localStorage.setItem("cityLocation", pickupCityLocation);
  localStorage.setItem("codeLocation", pickupCodeLocation);
  localStorage.setItem("addressLine1Location", pickupAddress1Location);
  localStorage.setItem("addressLine2Location", pickupAddress2Location);
  localStorage.setItem("postalCodeLocation", pickupPostalCodeLocation);
  localStorage.setItem("telephoneLocation", pickupTelephoneLocation);
  const extra = JSON.stringify(extraData);
  localStorage.setItem("totalIdr", totalIdr);
  localStorage.setItem("extraData", extra);
  localStorage.setItem("make", vehicle.category.make);
  localStorage.setItem("image", vehicle.category.image_url);
  localStorage.setItem("model", vehicle.category.model);
  localStorage.setItem("vehicle_sipp_code", vehicle.category.sipp_code);
  localStorage.setItem("transmission", vehicle.category.vehicle_transmission);
  localStorage.setItem("rate_code", vehicle.rate_totals.rate.rate_code);
  localStorage.setItem("amount", total);
  localStorage.setItem("last_name", lastName);
  localStorage.setItem("first_name", firstName);
  localStorage.setItem("email", email);
  localStorage.setItem("telephone", telephone);
  localStorage.setItem("age", age);
  localStorage.setItem("address_line_1", address_line_1);
  localStorage.setItem("address_line_2", address_line_2);
  localStorage.setItem("city", city);
  localStorage.setItem("postal_code", postal_code);

  const navigate = useNavigate();

  const handleCancel = async (book) => {
    const res = await axios.post(`${API_URL}/api/booking/cancel`, {
      book: book,
      emailForm: "",
    });

    if (res.status === 200) {
      alert("Booking berhasil dibatalkan");

      navigate("/makereservation", {
        state: {
          booking: book,
        },
      });
    }

    if (res.status !== 200) {
      alert(res.data.msg);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios.post(
          `${API_URL}/api/booking/search`,
          {
            book: booking,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (res.status === 200) {
          setHeader(res.data.booking.header);
          setDetail(res.data.booking.detail);
          setMidLink(res.data.booking.mid);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    if (pickupCoordinate & dropoffCoordinate) {
      setPickupLat(pickupCoordinate[0]);
      setPickupLng(pickupCoordinate[1]);
      setDropoffLat(dropoffCoordinate[0]);
      setDropoffLng(dropoffCoordinate[1]);
    }
  }, [dropoffCoordinate, pickupCoordinate]);

  const successGetCurrentLocation = (position) => {
    setCurrentLatitude(position.coords.latitude);
    setCurrentLongitude(position.coords.longitude);
    setErrorGetCurrentLocation(null);
  };

  const errorMessageGetCurrentLocation = (error) => {
    setErrorGetCurrentLocation(error.message);
  };

  const getPickUpDirectionUrl = () => {
    console.log(currentLatitude, currentLongitude, pickupLat, pickupLng);
    if (currentLatitude && currentLongitude && pickupLat && pickupLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${pickupLat},${pickupLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  const getDropOffDirectionUrl = () => {
    if (currentLatitude && currentLongitude && dropoffLat && dropoffLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${dropoffLat},${dropoffLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  const customIcon = new Icon({
    iconUrl: publicUrl + "assets/images/icon/marker.png",
    iconSize: [38, 38],
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGetCurrentLocation,
        errorMessageGetCurrentLocation
      );
    } else {
      setErrorGetCurrentLocation(
        "Geolocation is not supported in this browser"
      );
    }
  });

  const myLink = {
    color: "rgb(212, 0, 42)",
  };
  const myBorder = {
    borderBottom: "2px solid rgb(212, 0, 42)",
  };
  const myLinkDis = {
    color: "rgb(168, 168, 168)",
  };
  const myBorderDis = {
    borderBottom: "2px solid rgb(168, 168, 168)",
  };
  const selectLink = {
    border: "2px solid rgb(212, 0, 42)",
    color: "#140a55",
  };
  const btnLink = {
    backgroundColor: "rgb(212, 0, 42)",
    color: "rgb(255, 255, 255)",
    padding: "0px 50px",
  };

  return (
    <div>
      {paymentType !== "paylaters" ? (
        <div>
          <section>
            <div className="container">
              <div className="row mt-3">
                <div className="col-sm-6">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Rent ID</td>
                        <td>
                          <b>{booking}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Booking Code</td>
                        <td>
                          <b>{bookingNumber}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Reservation Status</td>
                        <td>
                          <b>{header.status_book}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Booking at</td>
                        <td>
                          <b>{format(requestTime, "MMMM dd, yyyy")}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-sm-6 text-end">
                  <a
                    className="btn py-1"
                    href={midLink}
                    target="_blank"
                    style={btnLink}
                    rel="noreferrer"
                  >
                    Pay Now
                  </a>
                  <br />
                  <button
                    style={btnLink}
                    className="btn py-1 mt-2"
                    onClick={(e) => handleCancel(header.rent_id, bookingNumber)}
                  >
                    Cancel Reservation
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-4">
            <div className="container">
              <div className="row mt-4">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 border-bottom">
                      <div className="row">
                        <div className="col-sm-6">
                          <p style={myLink}>Pick-Up</p>
                          <p>
                            <b>{pickupNameLocation}</b>
                            <br />
                            {pickupCityLocation}, {pickupCodeLocation}
                            <br />
                            {pickupAddress1Location}, {pickupAddress2Location},{" "}
                            {pickupPostalCodeLocation}, {pickupCodeLocation}
                            <br />
                            <b>{pickupTelephoneLocation}</b>
                            <br />
                            <b>Location Code: </b> {pickupCodeLocation}
                          </p>
                          <p>
                            <b>{format(pickupDate, "HH:mm a MMMM d, yyyy")}</b>
                          </p>
                        </div>
                        <div className="col-sm-6">
                          <p style={myLink}>Return</p>
                          <p>
                            <b>{dropoffNameLocation}</b>
                            <br />
                            {dropoffCityLocation},{dropoffCodeLocation}
                            <br />
                            {dropoffAddress1Location},{dropoffAddress2Location},
                            {dropoffPostalCodeLocation}
                            <br />
                            <b>{dropoffTelephoneLocation}</b>
                            <br />
                            <b>Location Code: </b> {dropoffCodeLocation}
                          </p>
                          <p>
                            <b>{format(dropoffDate, "HH:mm a MMMM d, yyyy")}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 border-start border-bottom">
                      <div className="row">
                        <div className="col-sm-6">
                          <p>Base Rate</p>
                          <p style={myLink}>Taxes & Fees</p>
                          <p>Estimated Total</p>
                          <p style={myLink}>See Rate Terms</p>
                        </div>
                        <div className="col-sm-6 text-end">
                          <p>IDR {priceIdr.toLocaleString("id")}</p>
                          <p>IDR {taxIdr.toLocaleString("id")}</p>
                          <p>
                            <b>IDR {totalIdr.toLocaleString("id")}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="row mt-2">
                        <div className="col-sm-3">
                          <img
                            alt="category"
                            src={vehicle.category.image_url}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-sm-9">
                          <p>
                            <h5 className="text-dark">
                              {vehicle.category.name}
                            </h5>
                          </p>
                          <p>
                            {vehicle.category.make} {vehicle.category.model}
                          </p>
                          <p>
                            <img
                              alt="user"
                              src="/assets/images/icon/user.png"
                            />{" "}
                            {vehicle.capacity.seats}
                            <img
                              alt="suitcase"
                              src="/assets/images/icon/suitcase.png"
                              className="ms-2"
                            />{" "}
                            {vehicle.capacity.luggage_capacity.large_suitcase}
                            L
                            <img
                              alt="suitcase"
                              src="/assets/images/icon/suitcase.png"
                              className="ms-2"
                            />{" "}
                            {vehicle.capacity.luggage_capacity.large_suitcase}S
                          </p>
                          <p>{vehicle.category.vehicle_transmission}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 border-start text-end text-success">
                      <p>Other Information</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="container my-5">
          <section id="thank-you-note" className="mt-4">
            <div className="d-flex flex-column w-100 align-items-center text-center grid gap-3">
              <CiCircleCheck style={{ fontSize: "100px", color: "#4fdb6d" }} />
              <div
                style={{ color: "#4fdb6d", fontWeight: "bolder" }}
                className="fs-2"
              >
                THANK YOU.
              </div>
              <div className="fs-5">
                Your booking is complete. We'll send you a confirmation email
                shortly.
              </div>
              <div className="fs-5">Your reference: {bookingNumber}</div>
              <div style={{ fontSize: "18px" }}>
                Please read our{" "}
                <Link
                  to={process.env.PUBLIC_URL + `/`}
                  style={{
                    textDecoration: "underline",
                    color: "red",
                  }}
                >
                  terms and condition
                </Link>
              </div>
            </div>
          </section>

          {/* Car Information */}
          <section id="car-information" className="mt-4">
            <div
              style={{ backgroundColor: "#ebe8e8" }}
              className="d-flex flex-column grid"
            >
              <div className="d-flex justify-content-between">
                <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
                  Your Booking
                </div>
                <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
                  Booking Date:{" "}
                  {format(new Date(requestTime), "dd MMMM yyyy, HH:mm")}
                </div>
              </div>

              <div
                style={{
                  borderTop: "1px solid #d1d1d1",
                  borderBottom: "1px solid #d1d1d1",
                }}
              >
                <div className="px-4">
                  <div className="row">
                    <div
                      className="col-sm py-4"
                      style={{ borderRight: "1px solid #d1d1d1" }}
                    >
                      <div className="text-danger">When & Where</div>
                      <p>
                        {format(pickupDate, "dd MMM yyyy HH:mm a")} -{" "}
                        {format(dropoffDate, "dd MMM yyyy HH:mm a")}
                      </p>
                      <p>{pickupCodeLocation}</p>
                    </div>
                    <div
                      className="col-sm py-4"
                      style={{ borderRight: "1px solid #d1d1d1" }}
                    >
                      <div className="row mt-2">
                        <div className="col-sm-12">
                          <p>
                            <h5 className="text-dark">
                              {vehicle.category.make}
                            </h5>
                          </p>
                          <p>
                            {vehicle.category.make ?? ""}{" "}
                            {vehicle.category.model ?? ""}
                          </p>
                          <p>{vehicle.category.vehicle_transmission ?? ""}</p>
                        </div>
                      </div>
                      <div className="col-sm-12 d-flex justify-content-center">
                        <img
                          alt="CarImage"
                          src={vehicle.category.image_url ?? ""}
                          className="img-fluid"
                          width={200}
                        />
                      </div>
                    </div>
                    <div className="col-sm py-4">
                      <div className="text-danger">Insurance</div>
                      <ul className="text-end">
                        {extraData.map((item, index) => (
                          <li key={index}>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end p-4">
                <div style={{ marginRight: "250px" }}>Total</div>
                <div>
                  {vehicle.rate_totals.rate.currency} {total.toFixed(2)}
                </div>
              </div>
            </div>
          </section>

          {/* Information */}
          <section id="information" className="mt-4">
            <div className="px-4">
              <div className="row">
                <div className="col-sm d-flex flex-column grid gap-3">
                  <div className="fs-5" style={{ fontWeight: "bold" }}>
                    When you collect your car
                  </div>
                  <div>
                    One of your peyser cords must be in the name of the main
                    driver unless booked on a centrally billed company credit
                    card or account and you'll need to present the following at
                    the counter:
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>A valid driving license</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>1 payment card</div>
                  </div>
                </div>
                <div className="col-sm d-flex flex-column grid gap-3">
                  <div className="fs-5">Your reservation includes:</div>
                  <div className="fs-5">
                    (Inclusions are subject to your agreed/contracted rates)
                  </div>
                  <div className="d-flex">
                    <div
                      className="d-flex flex-column grid gap-3"
                      style={{ marginRight: "150px" }}
                    >
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>Airport subcharge</div>
                      </div>
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>Local tax</div>
                      </div>
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>Vehicle Luggage Free</div>
                      </div>
                    </div>
                    <div className="d-flex flex-column grid gap-3">
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>Theft cover</div>
                      </div>
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>Damage cover</div>
                      </div>
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>Unlimited</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Rental Information */}
          <section id="rental-information" className="my-4">
            <div style={{ backgroundColor: "#ebe8e8" }} className="p-4">
              <div className="fs-5" style={{ fontWeight: "bold" }}>
                Getting to your rental station
              </div>
              <div class="mt-4">
                <div class="row">
                  <div class="col-sm grid gap-2 d-flex flex-column">
                    <div style={{ color: "rgb(212, 0, 42)" }}>Pick Up</div>
                    <p>
                      <b>{pickupNameLocation ?? ""}</b>
                      <br />
                      {pickupCityLocation ?? ""}, {pickupCodeLocation ?? ""}
                      <br />
                      {pickupAddress1Location}, {pickupAddress2Location ?? ""},{" "}
                      {pickupPostalCodeLocation ?? ""}, <br />
                      <b>Location Code: </b> {pickupCodeLocation ?? ""}
                    </p>
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaPhoneAlt color="red" />
                      <div>tel: {pickupTelephoneLocation ?? ""}</div>
                    </div>
                    {pickupLat && pickupLng && (
                      <Link
                        onClick={getPickUpDirectionUrl}
                        style={{
                          textDecoration: "underline",
                          color: "red",
                        }}
                      >
                        Get directions
                      </Link>
                    )}

                    {!pickupLat && !pickupLng && (
                      <div className="mt-3 text-danger">
                        Sorry we can't find dropoff route navigation
                      </div>
                    )}

                    {pickupLat && pickupLng && (
                      <div className="mt-3">
                        <MapContainer
                          center={[pickupLat, pickupLng]}
                          zoom={13}
                          scrollWheelZoom={false}
                          style={{ height: "350px" }}
                          className="w-100"
                        >
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker
                            icon={customIcon}
                            position={[pickupLat, pickupLng]}
                          >
                            <Popup>
                              <p>
                                <b>{pickupNameLocation ?? ""}</b>
                                <br />
                                {pickupCityLocation ?? ""},{" "}
                                {pickupCodeLocation ?? ""}
                                <br />
                                {pickupAddress1Location ?? ""},{" "}
                                {pickupAddress2Location ?? ""},{" "}
                                {pickupPostalCodeLocation ?? ""},{" "}
                                {pickupCodeLocation ?? ""}
                                <br />
                                <b>{pickupTelephoneLocation ?? ""}</b>
                                <br />
                                <b>Location Code: </b>{" "}
                                {pickupCodeLocation ?? ""}
                              </p>
                            </Popup>
                          </Marker>
                        </MapContainer>
                      </div>
                    )}
                  </div>
                  <div class="col-sm grid gap-2 d-flex flex-column">
                    <div style={{ color: "rgb(212, 0, 42)" }}>Drop Off</div>
                    <p>
                      <b>{dropoffNameLocation ?? ""}</b>
                      <br />
                      {dropoffCityLocation ?? ""}, {dropoffCodeLocation ?? ""}
                      <br />
                      {dropoffAddress1Location ?? ""},{" "}
                      {dropoffAddress2Location ?? ""},{" "}
                      {dropoffPostalCodeLocation ?? ""},{" "}
                      {dropoffCodeLocation ?? ""}
                      <br />
                      <b>Location Code: </b> {dropoffCodeLocation ?? ""}
                    </p>
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaPhoneAlt color="red" />
                      <div>tel: {dropoffTelephoneLocation ?? ""}</div>
                    </div>
                    {dropoffLat && dropoffLng && (
                      <Link
                        onClick={getDropOffDirectionUrl}
                        style={{
                          textDecoration: "underline",
                          color: "red",
                        }}
                      >
                        Get directions
                      </Link>
                    )}

                    {!dropoffLat && !dropoffLng && (
                      <div className="mt-3 text-danger">
                        Sorry we can't find dropoff route navigation
                      </div>
                    )}
                    {dropoffLat && dropoffLng && (
                      <div className="mt-3">
                        <MapContainer
                          center={[dropoffLat, dropoffLng]}
                          zoom={13}
                          scrollWheelZoom={false}
                          style={{ height: "350px" }}
                          className="w-100"
                        >
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker
                            icon={customIcon}
                            position={[dropoffLat, dropoffLng]}
                          >
                            <Popup>
                              <p>
                                <b>{dropoffNameLocation ?? ""}</b>
                                <br />
                                {dropoffCityLocation ?? ""},{" "}
                                {dropoffCodeLocation ?? ""}
                                <br />
                                {dropoffAddress1Location ?? ""},{" "}
                                {dropoffAddress2Location ?? ""},{" "}
                                {dropoffPostalCodeLocation ?? ""},{" "}
                                {dropoffCodeLocation ?? ""}
                                <br />
                                <b>{dropoffTelephoneLocation ?? ""}</b>
                                <br />
                                <b>Location Code: </b>{" "}
                                {dropoffCodeLocation ?? ""}
                              </p>
                            </Popup>
                          </Marker>
                        </MapContainer>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default BD;
